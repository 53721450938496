import { IStandings } from '@/models/standings';

// TMP fix, await reply. Should be refactored when specs of legend are clear
export const showClubname = (club: IStandings) => {
	if (club.contestantCode === 'VIT') {
		return `${club.contestantClubName} *`;
	} else if (club.contestantCode === 'DBO') {
		return `${club.contestantClubName} +`;
	}
	return club.contestantClubName;
};
