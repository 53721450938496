import React, { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Container from '../components/container';
import MoreStories from '../components/more-stories';
import MoreStoriesList from '../components/more-stories-list';
import HeroPost from '../components/hero-post';
import Layout from '../components/layout';
import NavBar from '../components/navbar';
import Tabs from '../components/tabs';
import MoreVideoPosts from '../components/more-video-posts';
import PeriodeItem from '../components/periode-item';
import RoundsTable from '../components/rounds-table';
import Icon from '../components/icon/Icon';
import SocialWall from '../components/social-wall';
import config from '../lib/config';

import { GetServerSideProps } from 'next';
import { fetchAPI, fetchData, getAllPostsForHome, getPrimaryMenuItems } from '../lib/api';
import { H2, H4 } from '../components/text';
import { Swiper, SwiperSlide } from 'swiper/react';
import { VideoCard } from '../components/video-card';
import { Autoplay } from 'swiper/modules';
import { IHomePosts } from '../models/home-post';
import { ISponsor } from '../models/sponsor';
import { IconButton } from '../components/icon-button';
import { StandingsColumLayout } from '../components/standings-item';
import { PeriodTitleMapping } from '../models/period-title';
import { IMatchRoundData } from '../models/matchRoundData';
import { IPeriodStanding } from '../models/period-standing';
import { IStandings } from '../models/standings';
import { LinkButton } from '../components/link-button';
import { IMenuItem } from '../models/menu-item';
import SideBarBanner from '../components/sidebar-banner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Countdown from '@/components/countdown';

interface IProps {
	posts: IHomePosts;
	sponsors: ISponsor[];
	preview: boolean;
	primaryMenuItems: IMenuItem[];
	periodStandings: { [key in PeriodTitleMapping]: IPeriodStanding[] };
	standings: IStandings[];
	matchRoundsData: IMatchRoundData;
}

export default function Index({
	primaryMenuItems,
	posts,
	sponsors,
	preview,
	standings,
	periodStandings,
	matchRoundsData,
}: IProps) {
	const [roundData, setRoundData] = useState<IMatchRoundData | null>(null);
	const [currentRound, setCurrentRound] = useState(matchRoundsData?.currentRound);

	const videoPosts = useMemo(() => {
		const [firstPost, ...restPosts] = posts.videoPosts.edges;
		return { firstPost, restPosts };
	}, [posts.videoPosts]);

	const newsPosts = useMemo(() => {
		const [firstPost, secondPost, ...restPosts] = posts.newsPosts.edges;
		return {
			highlightPosts: [firstPost, secondPost],
			restPosts,
		};
	}, [posts.newsPosts]);

	useEffect(() => {
		const fetchRoundData = async () => {
			try {
				const response = await fetch(
					`${config.apiUrl}/wp-json/statsperform/v1/round-schedule?round=${currentRound}`,
				);
				const data = await response.json();
				setRoundData(data);
			} catch (error) {
				console.error('Error fetching round data:', error);
			}
		};
		fetchRoundData();
	}, [currentRound]);

	const filteredSponsors = useMemo(() => {
		const newSponsors = sponsors.filter((node) => !node.sponsor_fields.subtitle.toLowerCase().includes('supplier'));

		// Temp fix for bug in Slider
		return [...newSponsors, ...newSponsors];
	}, [sponsors]);

	const tabs = [
		{
			label: 'Klassement',
			children: <StandingsColumLayout clubs={standings} />,
		},
		{
			label: 'Periodestand',
			children: <PeriodeItem rankingsData={periodStandings} inverted />,
		},
	];

	const pageTitle = `${config.siteName}: Het laatste nieuws, uitslagen, standen en samenvattingen`;

	return (
		<Layout preview={preview} sponsors={sponsors} metaTitle={pageTitle} metaDesc={pageTitle}>
			<Head>
				<title>{pageTitle}</title>
			</Head>
			<NavBar items={primaryMenuItems} />
			<section className="mb-12 lg:mb-16 pt-0">
				{posts.page.heroSlider.heroPosts.length > 0 && <HeroPost data={posts.page.heroSlider.heroPosts} />}
			</section>
			<section className="mb-12">
				<Container>
					<div className="flex flex-col lg:flex-row">
						<div className="w-full lg:w-4/12 xl:w-3/12  mb-12 h-full">
							<SideBarBanner />
							<div className="bg-black-500">
								{matchRoundsData && <Countdown matchRoundsData={matchRoundsData} />}
								{roundData && (
									<div className="w-full h-full relative pb-4">
										<div className="swiper-title pb-4 flex flex-row justify-center items-center">
											<IconButton
												icon="arrow-left"
												className="mr-2 bg-transparent"
												onClick={() => setCurrentRound(roundData.prevRound)}
												disabled={!roundData.prevRound}
											/>
											<H4 className="text-white mx-1 lg:mx-3 text-base lg:text-lg xl:text-xl text-center uppercase">
												Speelronde {roundData.currentRound}
											</H4>
											<IconButton
												icon="arrow-right"
												className="ml-2 bg-transparent"
												onClick={() => setCurrentRound(roundData.nextRound)}
												disabled={!roundData.nextRound}
											/>
										</div>

										<RoundsTable matchesData={roundData} snug />

										<div className="my-8 flex justify-center items-center">
											<Link href={'/programma'} className="flex items-center">
												<span className="inline-block mr-3 text-red-500 hover:text-red-700 font-inter font-bold uppercase tracking-wide text-base lg:text-lg">
													Alle wedstrijden
												</span>
												<Icon icon={'arrow-right'} color="#E2013D" size={18} />
											</Link>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="flex-1 lg:pl-6 xl:pl-16 w-full lg:w-8/12 xl:w-9/12">
							<section className="mb-12">
								<div className="md:flex justify-between items-center mb-4">
									<H2 className="text-center lg:text-left">Samenvattingen</H2>
									<LinkButton size="small" href="/video" className="hidden md:block">
										Alle video&apos;s
									</LinkButton>
								</div>
								<div className="grid grid-cols-12 gap-2 md:gap-4 lg:gap-6">
									<div className="row-span-2 col-span-12 lg:col-span-6">
										{videoPosts && videoPosts.firstPost && (
											<VideoCard
												size="medium"
												bgColor="transparent"
												title={videoPosts.firstPost.node.title}
												imageUrl={videoPosts.firstPost.node.featuredImage?.node.sourceUrl ?? ''}
												alt={videoPosts.firstPost.node.title}
												videoLink={`/video/${videoPosts.firstPost.node.slug}`}
												date={videoPosts.firstPost.node.date}
											/>
										)}
									</div>
									<div className="col-span-12 lg:col-span-6 mb-0">
										<div className="grid grid-rows-4 lg:grid-rows-2 grid-flow-col gap-2 md:gap-4">
											{videoPosts && videoPosts.restPosts.length > 0 && (
												<MoreVideoPosts posts={videoPosts.restPosts} />
											)}
										</div>
										<LinkButton
											size="small"
											href="/video"
											className="inline-block w-full text-center mt-4 md:hidden"
										>
											Alle video&apos;s
										</LinkButton>
									</div>
								</div>
							</section>
							<section className="mb-12 w-full">
								<div className="bg-white px-4 py-4 lg:pt-8 lg:px-8 rounded-md">
									<Swiper
										modules={[Autoplay]}
										spaceBetween={40}
										loop
										autoplay={{
											delay: 2000,
											disableOnInteraction: false,
										}}
										className="partners-slider"
										breakpoints={{
											576: {
												slidesPerView: 2,
											},
											768: {
												slidesPerView: 3,
											},
											1280: {
												slidesPerView: 4,
											},
											1440: {
												slidesPerView: 5,
											},
										}}
									>
										{filteredSponsors.map((sponsor, i) => (
											<SwiperSlide key={i}>
												<Link href={sponsor.sponsor_fields.websiteUrl} target="_blank">
													<img
														src={sponsor.featuredImage.node.sourceUrl}
														className="object-contain w-full select-none lg:h-16 lg:w-auto"
														alt={sponsor.sponsor_fields.subtitle || 'Onze partnersp'}
													/>
												</Link>
											</SwiperSlide>
										))}
									</Swiper>
								</div>
							</section>
							<section className="w-full">
								<H2 className="mb-4 text-center lg:text-left">Laatste nieuws</H2>
								<div className="mb-0 lg:mb-6">
									{newsPosts.highlightPosts.length > 0 && (
										<MoreStories posts={newsPosts.highlightPosts} />
									)}
								</div>
								{newsPosts.restPosts.length > 0 && <MoreStoriesList posts={newsPosts.restPosts} />}
								<LinkButton
									size="small"
									href="/categorie/nieuws"
									className="inline-block w-full text-center mt-4 md:hidden"
								>
									Meer nieuws
								</LinkButton>
							</section>
						</div>
					</div>
				</Container>
			</section>

			<section className="bg-red-500 w-full h-auto py-12 lg:py-16">
				<Container>
					<Tabs tabs={tabs} tabColorInterted />
				</Container>
			</section>

			<section className="bg-white w-full h-auto pt-12">
				<Container>
					<SocialWall />
				</Container>
			</section>
		</Layout>
	);
}

const fetchStandingsData = async () => {
	const endpoint = `${config.apiUrl}/wp-json/statsperform/v1/standings`;
	return fetchData(endpoint);
};

const fetchPeriodStandingsData = async () => {
	const periodStandingEndpoint = `${config.apiUrl}/wp-json/statsperform/v1/period-standings`;
	return fetchData(periodStandingEndpoint);
};

const fetchRoundMatchData = async () => {
	const matchRoundsData = `${config.apiUrl}/wp-json/statsperform/v1/current-round`;
	return fetchData(matchRoundsData);
};

export const getServerSideProps: GetServerSideProps = async ({ preview = false }) => {
	const data = await getAllPostsForHome();
	const { sponsors, ...posts } = data;
	const primaryMenuItems = await getPrimaryMenuItems();

	const pageData = await fetchAPI(
		`
		query {
			page(id: 9318, idType: DATABASE_ID) {
				playOffsRounds {
					playOffs {
					  away
					  home
					  awaylogo {
						altText
						sourceUrl
					  }
					  awayscore
					  date
					  homelogo {
						altText
						sourceUrl
					  }
					  homescore
					  played
					  time
					}
				}
			}
		}
	`,
	);

	let standings: IStandings[] | null = null;
	let periodStandings: { [key in PeriodTitleMapping]: IPeriodStanding[] } | null = null;
	let matchRoundsData: IMatchRoundData | null = null;

	try {
		standings = await fetchStandingsData();
		periodStandings = await fetchPeriodStandingsData();
		matchRoundsData = await fetchRoundMatchData();
	} catch (error) {
		console.error('Error fetching data:', error);
	}

	return {
		props: {
			posts,
			sponsors: sponsors.nodes,
			preview,
			primaryMenuItems,
			periodStandings,
			standings,
			pageData,
			matchRoundsData,
		},
	};
};
